package ff.components.complex

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.backgroundImage
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import org.w3c.files.File

@Composable
fun ffImageUpload(
    url: String?,
    uploader: (File) -> String
) {
    Div(attrs = {
        style {
            width(80.px)
            height(80.px)
            property("border", "1px solid #dadada")
            url?.let { backgroundImage("url('$it')") }
            property("object-fit", "cover")
        }
    }) {

    }
    if (url != null) {
        Img(url)
    } else {
        ffIcon(FFIcon.UPLOAD_CLOUD)
        Text("Add")
    }
}
