package ff.auth

import androidx.compose.runtime.Composable
import com.soywiz.krypto.SHA256
import com.soywiz.krypto.SecureRandom
import com.soywiz.krypto.encoding.Base64
import com.soywiz.krypto.encoding.base64
import ff.http.HttpUtil
import ff.state.FFStateStore
import kotlinx.browser.window
import kotlinx.serialization.Serializable
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import kotlin.random.Random

private val ffAuthStateStore = FFStateStore.init("ff-auth-state", Auth0Credentials())

class Auth0Client(
    private val domain: String,
    private val clientId: String,
    private val audience: String,
    internal val redirectUrl: String,
    ) {

    fun getUserInfo(callback: (Auth0UserInfo) -> Unit) {
        if (ffAuthStateStore.state.userInfo != null) {
            callback(ffAuthStateStore.state.userInfo!!)
        } else {
            HttpUtil.get<Auth0UserInfo>(
                url = "https://contester.eu.auth0.com/userinfo",
                headers = mapOf("Authorization" to "Bearer ${ffAuthStateStore.state.accessToken}",
                    "Content-Type" to "application/json",
                    "Accept" to "application/json", ), onCompleted = {
                        ffAuthStateStore.state {
                            userInfo = it
                        }
                        callback(it)
                })
        }
    }

    fun login() {

        console.log("Login attempt")

        fun base64(arr: ByteArray) = arr.base64.replace("+", "-").replace("/", "_").replace("=", "")

        val verifier = base64(SecureRandom.nextBytes(32))
        val challenge = base64(SHA256.digest(verifier.encodeToByteArray()).bytes)

        window.location.href = """
              https://$domain/authorize?
              response_type=code&
              client_id=$clientId&
              code_challenge=$challenge&
              code_challenge_method=S256&
              redirect_uri=$redirectUrl&
              scope=openid profile email&
              audience=$audience&
              state=$verifier
        """.trimIndent()

    }

    fun logout() {
        ffAuthStateStore.reset()
        window.location.href = "https://$domain/v2/logout?client_id=$clientId&returnTo=$redirectUrl"
    }

    fun handleCode(code: String, state: String) {

        HttpUtil.post<AuthorizationTokenResponse>("https://$domain/oauth/token", body = """
            grant_type=authorization_code&client_id=$clientId&code_verifier=$state&code=$code&redirect_uri=$redirectUrl
        """.trimIndent(), onCompleted = {
            ffAuthStateStore.state {
                accessToken = it.access_token
                expiresIn = it.expires_in
                tokenType = it.token_type
            }
            window.location.href = redirectUrl
        })
    }

    val token: String
        get() = ffAuthStateStore.state.accessToken!!


}

@Serializable
data class Auth0UserInfo(
    val email: String,
    val email_verified: Boolean,
    val name: String,
    val nickname: String,
    val picture: String,
    val sub: String,
    val updated_at: String,
)

@Serializable
data class AuthorizationTokenResponse(
    val access_token: String,
    val expires_in: Int,
    val token_type: String,
    val id_token: String,
    val scope: String,
)

@Composable
fun ffAuthorized(client: Auth0Client, content: @Composable () -> Unit) {

    if (ffAuthStateStore.state.accessToken != null) {
        content()
    } else if (window.location.href.startsWith(client.redirectUrl) && window.location.search.isNotEmpty() && window.location.search.contains("code")) {
        val params = HttpUtil.searchParams()
        console.log("OMG $params")
        client.handleCode(params["code"]!!, params["state"]!!)

    } else {
        console.log("Not authenticated")
        client.login()
    }
}