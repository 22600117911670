package ff.components.complex

import androidx.compose.runtime.*
import ff.components.ffButton
import ff.data.NumberedList
import org.jetbrains.compose.web.dom.*

@Composable
fun <T> ffTable(
    dataRequest: ((Int, Int, ((NumberedList<T>) -> Unit)) -> Unit),
    columns: Array<FFTableColumnDefinition<T>>,
    autoFetch: Boolean = true,
) = ffPaginated(dataRequest, autoFetch) { data ->
    Table({ classes("hover") }) {
        Thead {
            Tr {
                columns.forEach { column ->
                    Th {
                        Text(column.title)
                    }
                }
            }
        }
        Tbody {
            data.forEach {
                Tr {
                    columns.forEach { column ->
                        Td {
                            column.provider(it)
                        }
                    }
                }
            }
        }
    }
}

data class FFTableColumnDefinition<T> private constructor(
    val title: String,
    val provider: @Composable (T) -> Unit,
) {
    companion object {

        fun <T> text(title: String, provider: (T) -> String) =
            FFTableColumnDefinition<T>(title) {
                Text(provider(it))
            }

        fun <T> button(title: String, text: String, onClick: (T) -> Unit) =
            FFTableColumnDefinition<T>(title) { row ->
                ffButton(attrs = { onClick { onClick(row) } }) {
                    Text(text)
                }
            }
    }
}