package ff.components

import androidx.compose.runtime.Composable
import ff.routing.Route
import ff.routing.Router
import org.jetbrains.compose.web.dom.*

@Composable
fun ffMenu(
    router: Router,
    items: List<Route>
) = Ul({ classes("menu", "vertical") }) {
    items.forEach { item ->
        Li({ if (router.activeRoute.path == item.path) classes("is-active") }) {
            A(attrs = { onClick { router.navigate(item) } }) { Text(item.title) }
        }
    }
}