package ff.http

import ff.auth.Auth0Client
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

abstract class ApiClient(
    val baseUrl: String,
    val auth0Client: Auth0Client,
) {



    inline fun <reified T> get(url: String,
                               crossinline onCompleted: (T) -> Unit,
    ) {
        HttpUtil.get("$baseUrl/$url", headers = mapOf(
            "Authorization" to "Bearer ${auth0Client.token}",
            "Content-Type" to "application/json",
            "Accept" to "application/json",
        ), onCompleted = onCompleted, onUnauthorized = {
            auth0Client.logout()
        }, onForbidden = { response ->
            if (response.contains("dataValidationError")) {
                val json = Json { ignoreUnknownKeys = true }

                val error: DataValidationError = json.decodeFromString(response)
                console.error("Request to '$url' returned the following data validation error: '${error.summary.title}'")
            }
        })
    }

    inline fun <reified T, reified K> post(url: String, body: T, crossinline onCompleted: (K) -> Unit) {
        HttpUtil.post("$baseUrl/$url", headers = mapOf(
            "Authorization" to "Bearer ${auth0Client.token}",
            "Content-Type" to "application/json",
            "Accept" to "application/json",
        ), onCompleted = onCompleted, body = Json.encodeToString(body), onUnauthorized = {
            auth0Client.logout()
        }, onForbidden = { response ->
            if (response.contains("dataValidationError")) {
                val json = Json { ignoreUnknownKeys = true }

                val error: DataValidationError = json.decodeFromString(response)
                console.error("Request to '$url' returned the following data validation error: '${error.summary.title}'")
            }
        })
    }
}

@Serializable
data class DataValidationError(
    val summary: DataValidationErrorSummary,
) {
    @Serializable
    data class DataValidationErrorSummary(
        val title: String,
    )
}