import androidx.compose.runtime.Composable
import ff.components.FFColor
import ff.components.complex.ffConfirmation
import ff.components.complex.ffPaginated
import ff.components.ffButton
import ff.components.ffGridX
import ff.components.ffGridXCell
import ff.components.premade.PageContext
import ff.data.NumberedList
import ff.log.trace
import net.contester.admin.LocalRoute
import net.contester.admin.api.BrandData
import net.contester.admin.api.ContesterApiClient
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

@Composable
fun pageBrands(context: PageContext<ContesterApiClient>) {

    ffPaginated<BrandData>(dataRequest = { page, perPage, callback ->
        run {
            context.api.brands(
                page,
                perPage
            ) { callback(NumberedList.create(it)) }
        }
    }, true, 9) { data ->
        context.loadingCompleted()
        ffGridXCell(12, 9, 9) {
            ffGridX(marginX = true, marginY = true) {
                data.forEach { brand ->
                    ffGridXCell(6, 4, 3) {
                        ffGridX(attrs = { classes("box-shadow") } ) {
                            ffGridXCell(12, 12, 12, attrs = { style { property("max-height", "160px") } }) {
                                Img(brand.imageUrl, attrs = { style { property("object-fit", "cover"); property("width", "100%"); property("height", "100%") } })
                            }
                            ffGridXCell(attrs = { style { property("padding", "5px 5px 5px 20px") }}) {
                                Text(brand.name)
                            }
                            ffGridXCell {
                                ffButton(color = FFColor.Primary, attrs = { style { property("margin", "5px") }; onClick { context.router.navigate(
                                    LocalRoute.BRAND_SINGLE, mapOf("id" to brand.id)) }}) {
                                    Text("Edit")
                                }
                                ffConfirmation("confirmation-${brand.id}", "Are you sure you want to delete brand '${brand.name}'?", onAccept = { trace("accepted") }, onDecline = { trace("declined") })
                                ffButton(color = FFColor.Warning, attrs = { style { property("margin", "5px") }; attr("data-open", "confirmation-${brand.id}")}) {
                                    Text("Delete")
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}