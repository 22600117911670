package ff.state

import ff.log.trace
import kotlinx.browser.localStorage

class FFStateStore<T>(private val key: String, private val default: T, initState: T) {

    val state: T = initState

    fun state(apply: T.() -> Unit) {
        state.run(apply)
        localStorage.setItem(key, JSON.stringify(state))
    }

    fun reset() {
        localStorage.setItem(key, JSON.stringify(default))
    }

    companion object {

        fun <T> init(key: String, ifNull: T): FFStateStore<T> {
            val state = try {
                JSON.parse<T>(localStorage.getItem(key)!!)
            } catch (e: Throwable) {
                trace("Error parsing state: ${e.message}")
                null
            }
            return FFStateStore(key, ifNull, state ?: ifNull)
        }
    }
}