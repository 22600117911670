package ff.charts

interface Type {
    companion object {
        inline val line: Type get() = Type("line")
        inline val bar: Type get() = Type("bar")

        inline val horizontalBar: Type get() = Type("horizontalBar")
        inline val radar: Type get() = Type("radar")
        inline val doughnut: Type get() = Type("doughnut")
        inline val polarArea: Type get() = Type("polarArea")
        inline val bubble: Type get() = Type("bubble")
        inline val pie: Type get() = Type("pie")
        inline val scatter: Type get() = Type("scatter")
    }
}

inline fun Type(value: String) = value.unsafeCast<Type>()

inline fun <T : Any> jso(): T = js("({})")

inline fun <T : Any> jso(builder: T.() -> Unit): T = jso<T>().apply(builder)

fun ChartInit() {
    Chart.register(
        ArcElement,
        LineElement,
        BarElement,
        PointElement,
        BarController,
        BubbleController,
        DoughnutController,
        LineController,
        PieController,
        PolarAreaController,
        RadarController,
        ScatterController,
        CategoryScale,
        LinearScale,
        LogarithmicScale,
        RadialLinearScale,
        TimeScale,
        TimeSeriesScale,
        Decimation,
        Filler,
        Legend,
        Title,
        Tooltip,
        SubTitle
    )
}