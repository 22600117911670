package ff.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.*

@Composable
fun ffGridX(
    marginX: Boolean? = null,
    marginY: Boolean? = null,
    paddingX: Boolean? = null,
    paddingY: Boolean? = null,
    id: String? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: ContentBuilder<HTMLDivElement>? = null
) = Div({ classes(classes = listOfNotNull(
    "grid-x",
    marginX?.let { "grid-margin-x" },
    marginY?.let { "grid-margin-y" },
    paddingX?.let { "grid-padding-x" },
    paddingY?.let { "grid-padding-y" },
    ).toTypedArray()); id?.let { id(it) }; attrs?.invoke(this) }, content)

@Composable
fun ffCallout(
    color: FFColor = FFColor.Primary,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: ContentBuilder<HTMLDivElement>? = null
) = Div({
    classes("callout", color.className)
    attrs?.invoke(this)
}, content)

enum class FFCallout

@Composable
fun ffGridXCell(
    small: Int? = null,
    medium: Int? = null,
    large: Int? = null,
    auto: Boolean? = null,
    id: String? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: ContentBuilder<HTMLDivElement>? = null
) = Div({ classes(classes = (listOfNotNull(
    "cell",
    small?.let { "small-$it" },
    medium?.let { "medium-$it" },
    large?.let { "large-$it" },
    auto?.let { "auto" },
    )).toTypedArray()); id?.let { id(it) }; attrs?.invoke(this) }, content)

@Composable
fun ffGridContainer(
    classes: List<String> = listOf(),
    content: ContentBuilder<HTMLDivElement>? = null
) = Div({ classes(classes = (classes + "grid-container").toTypedArray()) }, content)

@Composable
fun ffButton(
    attrs: AttrBuilderContext<HTMLButtonElement>? = null,
    color: FFColor = FFColor.Primary,
    content: ContentBuilder<HTMLButtonElement>? = null
) = Button({ attrs?.invoke(this); classes(classes = listOf("button", color.className)) }, content)

enum class FFColor(val className: String) {
    Primary("primary"),
    Secondary("secondary"),
    Success("success"),
    Alert("alert"),
    Warning("warning"),
}

@Composable
fun ffInputText(
    label: String,
    value: String,
    disabled: Boolean = false,
    onInput: (String) -> Unit = { },
    helpText: String? = null,
    placeholder: String? = null,
    attrs: AttrBuilderContext<HTMLInputElement>? = null,
) = Label {
    Text(label)
    Input(InputType.Text) {

        if (placeholder != null) {
            attr("placeholder", placeholder)
        }

        if (disabled)
            attr("disabled", "")
        value(value)
        onInput { onInput(it.value) }
        attrs?.invoke(this)
    }
    if (helpText != null) {
        P({ classes("help-text") }) {
            Text(helpText)
        }
    }
}

@Composable
fun ffInputNumber(
    label: String,
    value: String,
    disabled: Boolean = false,
    onInput: (Number?) -> Unit = { },
    helpText: String? = null,
    attrs: AttrBuilderContext<HTMLInputElement>? = null,
) = Label {
    Text(label)
    Input(InputType.Number) {
        if (disabled)
            attr("disabled", "")
        value(value)
        onInput { onInput(it.value) }
        attrs?.invoke(this)
    }
    if (helpText != null) {
        P({ classes("help-text") }) {
            Text(helpText)
        }
    }
}

@Composable
fun ffTextArea(
    label: String,
    value: String,
    onInput: (String) -> Unit = { },
    attrs: AttrBuilderContext<HTMLTextAreaElement>? = null,
) = Label {
    Text(label)
    TextArea(value) {
        onInput { onInput(it.value) }
        attrs?.invoke(this)
    }
}

@Composable
fun ffSelect(
    label: String,
    selected: String,
    options: List<String>,
    onChange: (String) -> Unit = { },
    attrs: AttrBuilderContext<HTMLSelectElement>? = null,
) = Label {
    Text(label)
    Select({
        onChange { onChange(it.value!!) }
        attrs?.invoke(this)
    }) {
        options.forEach { option ->
            Option(option, { if (option == selected) selected() }) { Text(option) }
        }
    }
}