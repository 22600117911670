package ff.components.complex

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.I

@Composable
fun ffIcon(icon: FFIcon) {
    I(attrs = { classes(icon.className) }) {  }
}

/**
 * https://zurb.com/playground/foundation-icon-fonts-3
 */
enum class FFIcon(val className: String) {
    LIST("fi-list"),
    UPLOAD_CLOUD("fi-upload-cloud"),
}