package ff.data

import kotlinx.serialization.Serializable

@Serializable
data class NumberedList<T>(
    val totalElements: Long,
    val totalPages: Int,
    val page: Int,
    val perPage: Int,
    val content: Array<T>
) {

    companion object {
        fun <T> create (items: List<T>) = NumberedList(items.size.toLong(), 1, 0, items.size, items.toTypedArray())
    }
}