package net.contester.admin.pages

import androidx.compose.runtime.*
import ff.components.*
import ff.components.complex.ffImageUpload
import ff.components.premade.PageContext
import net.contester.admin.api.BrandData
import net.contester.admin.api.ContesterApiClient
import org.jetbrains.compose.web.dom.Text

@Composable
fun pageBrandSingle(context: PageContext<ContesterApiClient>) {
    context.loadingStarted()

    val id = context.router.param("id")

    val edit = id != null
    var ready by remember { mutableStateOf(false) }

    var brand by remember { mutableStateOf<BrandData?>(null) }

    if (edit) {
        context.api.brandSingle(id!!) {
            brand = it
            context.loadingCompleted()
            ready = true
        }
    } else {
        context.loadingCompleted()
        ready = true
    }

    if (ready) {
        var updated by remember { mutableStateOf(false) }
        var name by remember { mutableStateOf(brand?.name) }
        var title by remember { mutableStateOf(brand?.title) }
        var description by remember { mutableStateOf(brand?.description) }
        var websiteUrl by remember { mutableStateOf(brand?.websiteUrl) }
        var websiteUrlTitle by remember { mutableStateOf(brand?.websiteUrlTitle) }

        fun update() {
            updated = false
        }

        ffGridX {

            ffGridXCell {
                ffInputText("Name", name ?: "", onInput = { updated = true; name = it })
            }

            ffGridXCell {
                ffInputText("Title", title ?: "", onInput = { updated = true; title = it })
            }

            ffGridXCell {
                ffInputText("Description", description ?: "", onInput = { updated = true; description = it })
            }

            ffGridXCell {
                ffInputText("Website URL", websiteUrl ?: "", onInput = { updated = true; websiteUrl = it }, helpText = "Used to redirect experiences to on last card clicks")
            }

            ffGridXCell {
                ffInputText("Website URL title", websiteUrlTitle ?: "", onInput = { updated = true; websiteUrlTitle = it })
            }

            ffGridXCell {
                ffImageUpload(brand?.imageUrl) {
                    ""
                }
            }

            ffGridXCell {
                ffImageUpload(null) {
                    ""
                }
            }

            ffGridXCell {
                ffButton({ if (!updated) attr("disabled", ""); onClick { if (updated) update() } }, color = FFColor.Primary) { Text("Save") }
            }
        }
    }



}