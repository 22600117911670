package ff.http

import ff.log.trace
import kotlinx.browser.window
import kotlinx.serialization.*
import kotlinx.serialization.json.*
import org.w3c.xhr.XMLHttpRequest

class HttpUtil {

    companion object {

        val json = Json { ignoreUnknownKeys = true }

        fun searchParams() = stringParams(window.location.search.drop(1))

        fun hashParams() = stringParams(window.location.hash)

        fun queryParams() = stringParams(window.location.href.split("?").lastOrNull() ?: "")

        private fun stringParams(string: String): Map<String, String> {
            return if (string.isNotEmpty()) {
                try {
                    string.split("&").map { it.split("=").let { it[0] to it[1] } }.toMap()
                } catch (e: Throwable) {
                    trace("Error parsing hash into a param map: '$string', error=${e.message}")
                    mapOf()
                }
            } else mapOf()
        }

        fun mapToQueryParams(map: Map<String, String>): String {
            return if (map.isEmpty()) {
                ""
            } else {
                val params = map.map { "${it.key}=${it.value}" }.joinToString("&")
                "?$params"
            }
        }

        inline fun <reified T> post(
            url: String,
            headers: Map<String, String> = mapOf(
                "Content-Type" to "application/x-www-form-urlencoded",
            ),
            body: String,
            crossinline onCompleted: (T) -> Unit = { },
            crossinline onError: (String) -> Unit = { },
            crossinline onRedirect: (String) -> Unit = { },
            crossinline onUnauthorized: () -> Unit = { },
            crossinline onForbidden: (String) -> Unit = { },
        ) {

            request(HttpMethod.POST, url, headers = headers, body = body) { request ->
                when (request.status) {
                    in 200..299 -> {
                        onCompleted(json.decodeFromString(request.responseText))
                    }
                    302.toShort() -> {
                        onRedirect(request.getResponseHeader("Location")!!)
                    }
                    403.toShort() -> {
                        onForbidden(request.responseText)
                    }
                    0.toShort() -> {
                        onUnauthorized()
                    }
                    else -> {
                        onError("Error making HTTP request '$url', received error body: ${request.responseText}")
                    }
                }
                trace("response at '$url' with status '${request.status}")
            }
        }

        inline fun <reified T> get(
            url: String,
            headers: Map<String, String> = mapOf(
                "Content-Type" to "application/json",
                "Accept" to "application/json",
            ),
            crossinline onCompleted: (T) -> Unit = { },
            crossinline onError: (String) -> Unit = { },
            crossinline onRedirect: (String) -> Unit = { },
            crossinline onUnauthorized: () -> Unit = { },
            crossinline onForbidden: (String) -> Unit = { },
        ) {

            request(HttpMethod.GET, url, headers = headers) { request ->
                when (request.status) {
                    in 200..299 -> {
                        val body = json.decodeFromString<T>(request.responseText)
                        onCompleted(body)
                    }
                    302.toShort() -> {
                        onRedirect(request.getResponseHeader("Location")!!)
                    }
                    403.toShort() -> {
                        onForbidden(request.responseText)
                    }
                    0.toShort() -> {
                        onUnauthorized()
                    }
                    else -> {
                        onError("Error making HTTP request '$url', received error body: ${request.responseText}")
                    }
                }
                trace("response at '$url' with status '${request.status}")
            }
        }

        fun request(method: HttpMethod, url: String, body: String? = null, headers: Map<String, String> = mapOf(), onCompleted: (XMLHttpRequest) -> Unit) {
            val request = XMLHttpRequest()

            request.open(method.name, url)
            headers.forEach { request.setRequestHeader(it.key, it.value) }


            request.onreadystatechange = {
                if (request.readyState == 4.toShort()) {
                    onCompleted(request)
                }
            }
            request.send(body)
        }

    }

    enum class HttpMethod {
        GET, POST, PUT, DELETE
    }
}