package net.contester.admin.pages

import androidx.compose.runtime.*
import ff.components.*
import ff.components.premade.PageContext
import net.contester.admin.api.ContesterApiClient
import org.jetbrains.compose.web.dom.Text

@Composable
fun pagePlatformActions(context: PageContext<ContesterApiClient>) {

    context.loadingStarted()
    context.loadingCompleted()

    var id by remember { mutableStateOf("") }

    fun triggerReloadExternalUrlValidations() {
        if (id.isNotEmpty()) {
            context.api.triggerReloadExternalUrlValidations(id) {  }
        } else {
            context.api.triggerReloadExternalUrlValidations(null) {  }
        }
        id = ""
    }

    fun triggerMediaStorageItemVideoProcessingForMissing() {
        context.api.triggerMediaStorageItemVideoTranscoding(id) { }
        id = ""
    }

    fun triggerVideoTranscodingPipelineMetadataFetching() {

        context.api.triggerVideoTranscodingPipelineMetadataFetching(id) { }
        id = ""
    }

    fun triggerDeleteProtectLivestreamRecordingPeriods() {
        context.api.deleteProtectLivestreamRecordingPeriods() {}
        id = ""
    }

    ffGridX {

        ffGridXCell {
            ffTextArea("ID", id, { id = it })
        }

        ffGridXCell {
            ffButton({ onClick { triggerReloadExternalUrlValidations() } }, color = FFColor.Primary) { Text("Reload external link validations (organizationId)") }
        }

        ffGridXCell {
            ffButton({ onClick { triggerMediaStorageItemVideoProcessingForMissing() } }, color = FFColor.Primary) { Text("Trigger video processing (mediaStorageItemId)") }
        }

        ffGridXCell {
            ffButton({ onClick { triggerDeleteProtectLivestreamRecordingPeriods() } }, color = FFColor.Primary) { Text("Trigger livestream recording statistics delete protection") }
        }
        ffGridXCell {
            ffButton({ onClick { triggerVideoTranscodingPipelineMetadataFetching() } }, color = FFColor.Primary) { Text("Trigger pipeline metadata processing (videoTranscodingPipelineId)") }
        }
        ffGridXCell {
            ffButton({ onClick { context.api.triggerAwinTransactionFillingMissingValues { } } }, color = FFColor.Primary) { Text("Trigger transaction data refilling") }
        }
        ffGridXCell {
            ffButton({ onClick { context.api.triggerAwinReaggregateSales { } } }, color = FFColor.Primary) { Text("Reaggregate Awin transactions") }
        }
        ffGridXCell {
            ffButton({ onClick { context.api.triggerReaggregateEmailEvents { } } }, color = FFColor.Primary) { Text("Reaggregate email tracker events") }
        }
        ffGridXCell {
            ffButton({ onClick {
                context.api.neutrinoIpInfoLookupDailyBatch(id) { }
                id = ""
            } }, color = FFColor.Primary) { Text("Neutrino IP info lookup for historical data (set limit in param, i.e. 9500)") }
        }
        ffGridXCell {
            ffButton({ onClick {
                context.api.rebuildStatisticsAggregates(id) { }
                id = ""
            } }, color = FFColor.Primary) { Text("rebuildStatisticsAggregates (productId param)") }
        }

        ffGridXCell {
            ffButton({ onClick {
                context.api.rebuildStatisticsAggregatesAll(id) { }
                id = ""
            } }, color = FFColor.Primary) { Text("rebuildStatisticsAggregatesAll (limit param, i.e. 100)") }
        }

        ffGridXCell {
            ffButton({ onClick {
                context.api.deduplicateStatisticsAggregates(id) { }
                id = ""
            } }, color = FFColor.Primary) { Text("deduplicateStatisticsAggregates") }
        }
    }
}