package ff.components.complex

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import ff.components.FFColor
import ff.components.ffButton
import ff.log.trace
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*

@Composable
fun ffConfirmation(id: String, message: String, onAccept: () -> Unit, onDecline: () -> Unit) {

    Div(attrs = {
        classes("reveal")
        id(id)
        attr("data-reveal", "")
        attr("data-close-on-esc", "false")
        attr("data-close-on-click", "false")
    }) {
        H2 {
            Text(message)
        }
        Span {
            ffButton(color = FFColor.Warning, attrs = { style { margin(10.px) }; attr("data-close", ""); onClick { onAccept(); js("$('html').removeClass('is-reveal-open');") }}) { Text("Yes") }
            ffButton(color = FFColor.Secondary, attrs = { style { margin(10.px) };attr("data-close", ""); onClick { onDecline(); js("$('html').removeClass('is-reveal-open');") }}) { Text("No") }
        }
    }
    DisposableEffect(id) {
        val selector = "#$id"
        js("$(selector).foundation();")
        onDispose {
        }
    }
}