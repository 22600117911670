package ff.components.complex

import androidx.compose.runtime.*
import ff.components.ffButton
import ff.data.NumberedList
import ff.log.trace
import org.jetbrains.compose.web.dom.Text

@Composable
fun <T> ffPaginated(
    dataRequest: ((Int, Int, ((NumberedList<T>) -> Unit)) -> Unit),
    autoFetch: Boolean = true,
    perPage: Int = 10,
    content: @Composable (Array<T>) -> Unit,
) {

    var data by remember { mutableStateOf(arrayOf<T>()) }
    var page by remember { mutableStateOf(0) }
    var totalElements by remember { mutableStateOf(0L) }
    var totalPages by remember { mutableStateOf(0) }
    var perPage by remember { mutableStateOf(perPage) }

    val callback: (NumberedList<T>) -> Unit = {
        page = it.page
        perPage = it.perPage
        totalElements = it.totalElements
        totalPages = it.totalPages
        data = it.content
        trace("ffPaginated.callback()")
    }

    if (autoFetch) {
        if (data.isEmpty()) {
            dataRequest(page, perPage, callback)
        }
    } else {
        ffButton({ onClick { dataRequest(page, perPage, callback) } }) { Text("Fetch") }
        ffButton({ onClick { data = arrayOf(); totalElements = 0; page = 0; totalPages = 0; } }) { Text("Clear") }
    }

    content(data)


    ffPagination(totalPages, page) { dataRequest(it, perPage, callback) }
}