package ff.routing

import androidx.compose.runtime.*
import ff.http.HttpUtil
import ff.log.trace
import kotlinx.browser.window

class Router(
    private val navigateFunc: (Route) -> Unit,
    private val activeRouteFunc: () -> Route,
) {

    fun navigate(route: Route, params: Map<String, String> = mapOf()) {
        trace("Router.navigate($route.path)")
        navigateFunc(route)
        window.location.href = "#${route.path}${HttpUtil.mapToQueryParams(params)}"
    }

    fun param(key: String) = HttpUtil.queryParams()[key]

    val activeRoute = activeRouteFunc()

    companion object {

        @Composable
        fun <T: Route> wrap(routes: Array<T>, func: @Composable (Router) -> Unit) {

            val (home, rest) = routes.partition { it.path == "" }.let {
                (it.first.singleOrNull() ?: throw IllegalStateException("No home path defined. Must include a single route with path=''")) to it.second
            }

            val route = if (!window.location.href.contains("#")) {
                home
            } else {
                val cleanPath = window.location.href.split("#")[1].split("?")[0]
                rest.firstOrNull { cleanPath == it.path } ?: home
            }

            trace("Router.initialize(${route.title})")

            var activeRoute: Route by remember { mutableStateOf(route) }

            val router = Router(activeRouteFunc = { activeRoute }, navigateFunc = { activeRoute = it })
            func(router)
        }
    }
}