package net.contester.admin.api

import ff.auth.Auth0Client
import ff.data.NumberedList
import ff.http.ApiClient
import kotlinx.serialization.Serializable

class ContesterApiClient(baseUrl: String, authClient: Auth0Client): ApiClient(baseUrl, authClient) {

    fun organizations(name: String?, page: Int, perPage: Int, result: (NumberedList<AdminOrganizationListProjection>) -> Unit) = get("api/admin/organizations?page=$page&perPage=$perPage&name=$name", result)
    fun brands(page: Int, perPage: Int, result: (List<BrandData>) -> Unit) = get("api/bo/brands?page=$page&perPage=$perPage", result)
    fun brandSingle(id: String, result: (BrandData) -> Unit) = get("api/bo/brands/$id", result)
    fun organizationSingle(id: String, result: (AdminOrganizationDetailsProjection) -> Unit) = get("api/admin/organizations/$id", result)

    fun organizationSingleUpdate(id: String, body: AdminOrganizationDetailsProjection, result: (Map<String, String>) -> Unit) = post("api/admin/organizations/$id", body, result)

    fun organizationLinkOrigin(id: String, body: OrganizationOriginLinkRequest, result: (Map<String, String>) -> Unit) = post("api/admin/organizations/$id/origins/link", body, result)
    fun organizationUnlinkOrigin(id: String, body: OrganizationOriginLinkRequest, result: (Map<String, String>) -> Unit) = post("api/admin/organizations/$id/origins/unlink", body, result)

    fun organizationAddAdministrator(id: String, body: OrganizationInviteRequest, result: (Map<String, String>) -> Unit) = post("api/admin/organizations/$id/administrators/add", body, result)
    fun organizationAddMember(id: String, body: OrganizationInviteRequest, result: (Map<String, String>) -> Unit) = post("api/admin/organizations/$id/members/add", body, result)
    fun organizationAddCreator(id: String, body: OrganizationInviteRequest, result: (Map<String, String>) -> Unit) = post("api/admin/organizations/$id/creators/add", body, result)
    fun organizationRemoveUser(id: String, body: OrganizationInviteRequest, result: (Map<String, String>) -> Unit) = post("api/admin/organizations/$id/users/remove", body, result)
    fun organizationReplaceOwner(id: String, body: OrganizationReplaceOwnerRequest, result: (Map<String, String>) -> Unit) = post("api/admin/organizations/$id/reassignOwner", body, result)

    fun experienceList(page: Int, perPage: Int, result: (NumberedList<ExperienceListData>) -> Unit) = get("api/bo/sequencepolls/list?page=$page&perPage=$perPage", result)

    fun triggerReloadExternalUrlValidations(organizationId: String?, result: (Map<String, String>) -> Unit) = post("api/admin/platform/actions/reloadExternalUrlValidations?organizationId=$organizationId", "", result)

    fun triggerMediaStorageItemVideoTranscoding(mediaStorageItemId: String, result: (Map<String, String>) -> Unit) = post("api/admin/platform/actions/triggerMediaStorageItemVideoTranscoding/$mediaStorageItemId", "", result)

    fun triggerVideoTranscodingPipelineMetadataFetching(pipelineId: String, result: (Map<String, String>) -> Unit) = post("api/admin/platform/actions/triggerVideoTranscodingMetadataRefetchAndProcessing/$pipelineId", "", result)
    fun deleteProtectLivestreamRecordingPeriods(result: (Map<String, String>) -> Unit) = post("api/admin/platform/actions/deleteProtectLivestreamRecordingPeriods", "", result)
    fun triggerAwinTransactionFillingMissingValues(result: (Map<String, String>) -> Unit) = post("api/admin/platform/actions/triggerAwinTransactionFillingMissingValues", "", result)
    fun triggerAwinReaggregateSales(result: (Map<String, String>) -> Unit) = post("api/admin/platform/actions/reaggregateSales", "", result)
    fun triggerReaggregateEmailEvents(result: (Map<String, String>) -> Unit) = post("api/admin/platform/actions/reaggregateEmailTrackerEvents", "", result)
    fun neutrinoIpInfoLookupDailyBatch(limit: String, result: (Map<String, String>) -> Unit) = post("api/admin/platform/actions/neutrinoIpInfoLookupDailyBatch/$limit", "", result)
    fun rebuildStatisticsAggregates(productId: String, result: (Map<String, String>) -> Unit) = post("api/admin/platform/actions/rebuildStatisticsAggregates/$productId", "", result)
    fun rebuildStatisticsAggregatesAll(limit: String, result: (Map<String, String>) -> Unit) = post("api/admin/platform/actions/rebuildStatisticsAggregatesAll/$limit", "", result)
    fun deduplicateStatisticsAggregates(productId: String, result: (Map<String, String>) -> Unit) = post("api/admin/platform/actions/deduplicateStatisticsAggregates/$productId", "", result)
}

@Serializable
data class OrganizationInviteRequest(
    val emails: String,
)

@Serializable
data class OrganizationReplaceOwnerRequest(
    val email: String
)

@Serializable
data class OrganizationOriginLinkRequest(
    val organizationId: String,
    val source: String,
    val origin: String,
)

@Serializable
data class BrandData(
    val id: String,
    val name: String,
    val title: String,
    val description: String,
    val websiteUrl: String,
    val websiteUrlTitle: String,

    val imageUrl: String,
    val posterUrl: String,

    val logo: MediaStorageItemData?,
    val poster: MediaStorageItemData?,
    val statistics: BrandStatisticsData,
)

@Serializable
data class MediaStorageItemData(
    val id: String,
    val location: String,
)

@Serializable
data class BrandStatisticsData(
    val sequencePollActiveCount: Int,
    val sequencePollArchivedCount: Int,
    val sequencePollTotalCount: Int,
)

@Serializable
data class ExperienceListData(
    val sequencePoll: SequencePoll,
    val brand: SequencePollBrand,
    val rootCards: List<SequencePollRootCard>,
    val experienceCollectionId: String,
) {
    @Serializable
    data class SequencePoll(
        val id: String?,
        val name: String,
        val status: String,
        val createdAt: List<Int>,
        val editable: Boolean,
        val publicUrl: String,
        val isInBrandFeaturedList: Boolean,
        val brandFeaturedImage: String?,
    )
    @Serializable
    data class SequencePollRootCard(
        val id: String?,
        val image: String,
    )

    @Serializable
    data class SequencePollBrand(
        val id: String?,
        val title: String?,
        val logo: String?,
    )
}

@Serializable
data class AdminOrganizationDetailsProjection(
    val id: String,
    val name: String,
    val type: String,
    val ownerEmail: String,
    val createdAt: Double,
    val subscriptionStatus: String,
    val subscriptionTier: String,
    val subscriptionManagedBy: String,

    val experienceLimit: Int,
    val brandLimit: Int,
    val originLimit: Int,
    val creatorAccountLimit: Int,
    val creatorExperienceLimit: Int,
    val memberAccountLimit: Int,

    val advertiserId: String?,
    val publisherId: String?,
    val commissionSchemeId: String?,

    val currency: String,
)

@Serializable
data class AdminOrganizationListProjection(
    val id: String,
    val name: String,
)