package ff.components.complex

import androidx.compose.runtime.Composable
import ff.components.ffGridX
import ff.components.ffGridXCell
import org.jetbrains.compose.web.dom.*
import kotlin.math.max
import kotlin.math.min

@Composable
fun ffPagination(
    totalPages: Int,
    page: Int,
    onClick: (Int) -> Unit,
) {
    if (totalPages > 1) {

        ffGridX(marginY = true, marginX = true) {
            ffGridXCell {

                val minItem = max(0, page - 2)
                val maxItem = min(minItem + 4, totalPages - 1)
                val items = (minItem..maxItem)

                @Composable
                fun li(item: Int) {
                    Li({ if (item == page) classes("current") }) {
                        if (item == page) {
                            Text("${item + 1}")
                        } else {
                            A("#", { if (item != page) onClick { onClick(item) } }) {
                                Text("${item + 1}")
                            }
                        }
                    }
                }

                Nav({ attr("aria-label", "Pagination")}) {
                    console.log("render pagination")
                    Ul({ classes("pagination") }) {

                        Li({ classes("pagination-previous"); if (page <= minItem) classes("disabled") else onClick { onClick(page - 1) } }) {
                            if (page <= minItem) {
                                Text("Previous ")
                                Span({ classes("show-for-sr") }) {
                                    Text("page")
                                }
                            } else {
                                A("#") {
                                    Text("Previous ")
                                    Span({ classes("show-for-sr") }) {
                                        Text("page")
                                    }
                                }
                            }
                        }
                        items.map { li(it) }

                        Li({ classes("pagination-next"); if (page + 1 >= totalPages) classes("disabled") else onClick { onClick(page + 1) } }) {
                            if (page + 1 >= totalPages) {
                                Text("Next ")
                                Span({ classes("show-for-sr") }) {
                                    Text("page")
                                }
                            } else {
                                A("#", { attr("aria-label", "Next page") }) {
                                    Text("Next ")
                                    Span({ classes("show-for-sr") }) {
                                        Text("page")
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}