package net.contester.admin

import ff.charts.*
import androidx.compose.runtime.*
import ff.auth.Auth0Client
import ff.components.*
import ff.components.complex.*
import ff.components.premade.PageContext
import ff.components.premade.ffBasicAdmin
import ff.data.NumberedList
import ff.routing.Route
import ff.routing.Router
import kotlinx.browser.window
import net.contester.admin.api.*
import net.contester.admin.pages.pageBrandSingle
import net.contester.admin.pages.pageOrganizationSingle
import net.contester.admin.pages.pagePlatformActions
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.renderComposable
import pageBrands

enum class LocalRoute(
    val menu: Boolean,
    override val title: String,
    override val path: String,
): Route {

    HOME(true, "Home", ""),
    ORGANIZATIONS(true, "Organizations", "/organizations"),
    ORGANIZATION_SINGLE(false, "Organization", "/organizations/single"),
    EXPERIENCE_LIST(true, "Experiences", "/experiences"),
    BRAND_LIST(true, "Brands", "/brands"),
    BRAND_SINGLE(false, "Brand", "/brands/single"),
    PLATFORM_ACTIONS(true, "Platform actions", "/platform/actions"),
}

val isProduction = window.location.origin == "https://superadmin.contester.net"

private val apiUrl =
    if (isProduction)
        "https://api.contester.net"
    else
        "https://staging-api.contester.net"

private val audience =
    if (isProduction)
        "https://api.contester.net"
    else
        "https://staging-api.contester.net"

private val auth0ClientId =
    if (isProduction)
        "AdJrflaeMwom7ukGAdEJ3CsCMrTy3xVT"
    else
        "dNr9r9nYuJ5qIMeVXATO5fKKJmI8LJP9"




val auth0Client = Auth0Client("contester.eu.auth0.com", auth0ClientId, audience, "${window.location.origin}/")
private val api = ContesterApiClient(apiUrl, auth0Client)

fun main() {

    renderComposable(rootElementId = "root") {
        Router.wrap(LocalRoute.values()) { router ->

            ffBasicAdmin(router, LocalRoute.values().filter { it.menu }, auth0Client, api, isProduction) { context ->

                when (router.activeRoute) {
                    LocalRoute.HOME -> homeContent(context)
                    LocalRoute.ORGANIZATIONS -> pageOrganizations(context)
                    LocalRoute.ORGANIZATION_SINGLE -> pageOrganizationSingle(context)
                    LocalRoute.EXPERIENCE_LIST -> pageExperiences(context)
                    LocalRoute.BRAND_LIST -> pageBrands(context)
                    LocalRoute.BRAND_SINGLE -> pageBrandSingle(context)
                    LocalRoute.PLATFORM_ACTIONS -> pagePlatformActions(context)
                    else -> notFound()
                }
            }
        }
    }
}

@Composable
fun pageExperiences(context: PageContext<ContesterApiClient>) {

    val placeholder = "https://staging-cd.contester.net/cdn-cgi/image/width=200,height=200,fit=scale-down,quality=85,format=auto/data/ddcd2781-158e-4319-a6ab-76b37b52d519/83b0f6b6-bb0e-42bb-bffc-0de472777274-.jpg"

    Style {
        ".root-card-image" {
            width(80.px)
            height(80.px)
        }
        ".experience-active" {
            property("box-shadow", "0px 0px 7px 0px rgb(177, 177, 177)")
        }
    }
    ffPaginated<ExperienceListData>(dataRequest = { page, perPage, callback ->
        run {
            api.experienceList(
                page,
                perPage
            ) { callback(it) }
        }
    }, true, 9) { data ->
        context.loadingCompleted()

        var activeExperience by remember { mutableStateOf<ExperienceListData?>(null) }

        ffGridX(marginX = true, marginY = true) {
            ffGridXCell(12, 9, 9) {
                ffGridX(marginX = true, marginY = true) {
                    data.forEach {
                        ffGridXCell(6, 6, 4) {
                            ffGridX(attrs = { classes("box-shadow"); if (activeExperience?.sequencePoll?.id == it.sequencePoll.id) classes("experience-active"); onClick { e -> activeExperience = it } }) {
                                ffGridXCell {
                                    ffGridX {
                                        for (i in 1..4) {
                                            val image = it.rootCards.elementAtOrNull(i-1)?.image ?: placeholder
                                            ffGridXCell(6, 6, 6, attrs = { style { property("max-height", "80px") } }) {
                                                Img(image, attrs = { style { property("object-fit", "cover"); property("width", "100%"); property("height", "100%") } })
                                            }
                                        }
                                    }
                                }
                                ffGridXCell {
                                    ffGridX {
                                        ffGridXCell(2, 2, 2) {
                                            Img(it.brand.logo ?: placeholder, attrs = { style { property("margin", "0.5rem") } })
                                        }
                                        ffGridXCell(auto = true) {
                                            H6(attrs = { style { property("margin", "1rem") } }) { Text(it.sequencePoll.name) }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ffGridXCell(12, 3, 3) {
                if (activeExperience != null) {
                    ffGridX(marginX = true, marginY = true, attrs = { classes("box-shadow" )}) {
                        ffGridXCell {
                            Text("Name: ${activeExperience?.sequencePoll?.name}")
                        }
                        ffGridXCell {
                            Text("Brand: ${activeExperience?.brand?.title}")
                        }
                        ffGridXCell {
                            Text("Date: ${activeExperience?.sequencePoll?.createdAt}")
                        }
                    }
                }
            }
        }
    }
}



@Composable
fun pageOrganizations(context: PageContext<ContesterApiClient>) = ffGridX {

    context.loadingCompleted()


    var tempName by remember { mutableStateOf<String?>(null ) }
    var name by remember { mutableStateOf<String?>(null) }

    ffGridX {
        ffGridXCell {
            ffInputText("Name", tempName ?: "", false, { tempName = it })
        }
        ffGridXCell {
            ffButton({ onClick {
                name = tempName ?: ""
                ff.log.trace("search")
            } }, color = FFColor.Primary) { Text("Search") }
        }
    }

    if (name != null) {
        ffTable(dataRequest = { page, perPage, callback ->
            run {
                api.organizations(
                    name,
                    page,
                    perPage,
                ) { callback(it); context.loadingCompleted() }
            }
        }, columns = arrayOf(
            FFTableColumnDefinition.text("Id") { it.id },
            FFTableColumnDefinition.text("Name") { if (it.name.length <= 40) it.name else "${it.name.substring(0, 40)}..." },
            FFTableColumnDefinition.button("View", "View") { row -> context.router.navigate(LocalRoute.ORGANIZATION_SINGLE, mapOf("id" to row.id)) }
        ))
    }
}




@Composable
fun notFound() = Div {
    Text("Not found")
}

@Composable
fun homeContent(context: PageContext<ContesterApiClient>) {
    context.loadingStarted()
    console.log("render home")
    Div {
        Text("Contester Admin Dashboard")
        context.loadingCompleted()

        val tree = FFTreeNode(1, null) {
            listOf(
                FFTreeNode(2, it) {
                    listOf(
                        FFTreeNode(3, it),
                        FFTreeNode(4, it),
                        FFTreeNode(5, it),
                        FFTreeNode(6, it),
                    )
                },
                FFTreeNode(21, it) {
                    listOf(
                        FFTreeNode(7, it) {
                           listOf(
                               FFTreeNode(8, it),
                               FFTreeNode(9, it),
                           )
                        },
                        FFTreeNode(10, it),
                        FFTreeNode(11, it),
                    )
                }
            )
        }

        FFTreeNode.renderTree(tree)

        ChartInit()

        var data by remember { mutableStateOf(listOf(12, 19, 3, 5, 2, 3)) }

        Canvas {
            DisposableEffect(data) {
                val chart = Chart(scopeElement, jso {
                    type = Type.bar
                    this.data = jso {
                        labels = arrayOf("Red", "Blue", "Yellow", "Green", "Purple", "Orange")
                        datasets = arrayOf(jso {
                            label = "# of Votes"
                            this.data = data.toTypedArray()
                            backgroundColor = arrayOf(
                                "rgba(255, 99, 132, 0.2)",
                                "rgba(54, 162, 235, 0.2)",
                                "rgba(255, 206, 86, 0.2)",
                                "rgba(75, 192, 192, 0.2)",
                                "rgba(153, 102, 255, 0.2)",
                                "rgba(255, 159, 64, 0.2)"
                            )
                            borderColor = arrayOf(
                                "rgba(255, 99, 132, 1)",
                                "rgba(54, 162, 235, 1)",
                                "rgba(255, 206, 86, 1)",
                                "rgba(75, 192, 192, 1)",
                                "rgba(153, 102, 255, 1)",
                                "rgba(255, 159, 64, 1)"
                            )
                            borderWidth = 1
                        })
                    }
                })
                onDispose {
                    chart.destroy()
                }
            }
        }
    }
}
