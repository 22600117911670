package net.contester.admin.pages

import androidx.compose.runtime.*
import ff.components.*
import ff.components.premade.PageContext
import net.contester.admin.api.*
import org.jetbrains.compose.web.dom.Hr
import org.jetbrains.compose.web.dom.Text

@Composable
fun pageOrganizationSingle(context: PageContext<ContesterApiClient>) {

    context.loadingStarted()
    val api = context.api
    val id = context.router.param("id")
    console.log("Loading single organization $id")

    var organization by remember { mutableStateOf<AdminOrganizationDetailsProjection?>(null) }

    api.organizationSingle(id!!) { organization = it }

    if (organization != null) {

        var updated by remember { mutableStateOf(false) }
        var name by remember { mutableStateOf(organization!!.name) }
        var type by remember { mutableStateOf(organization!!.type) }
        var currency by remember { mutableStateOf(organization!!.currency) }
        var subscription by remember { mutableStateOf(organization!!.subscriptionStatus) }
        var tier by remember { mutableStateOf(organization!!.subscriptionTier) }
        var managedBy by remember { mutableStateOf(organization!!.subscriptionManagedBy) }
        var experienceLimit by remember { mutableStateOf(organization!!.experienceLimit) }
        var brandLimit by remember { mutableStateOf(organization!!.brandLimit) }
        var originLimit by remember { mutableStateOf(organization!!.originLimit) }
        var creatorAccountLimit by remember { mutableStateOf(organization!!.creatorAccountLimit) }
        var creatorExperienceLimit by remember { mutableStateOf(organization!!.creatorExperienceLimit) }
        var memberAccountLimit by remember { mutableStateOf(organization!!.memberAccountLimit) }

        var advertiserId by remember { mutableStateOf(organization!!.advertiserId) }
        var publisherId by remember { mutableStateOf(organization!!.publisherId) }
        var commissionSchemeId by remember { mutableStateOf(organization!!.commissionSchemeId) }

        context.loadingCompleted()

        fun update() {
            console.log("About to send to API with name=$name and subscription=$subscription")
            api.organizationSingleUpdate(id, AdminOrganizationDetailsProjection(
                id = id,
                name = name,
                type = type,
                ownerEmail = organization!!.ownerEmail,
                createdAt = organization!!.createdAt,
                subscriptionStatus = subscription,
                subscriptionTier = tier,
                subscriptionManagedBy = managedBy,
                experienceLimit = experienceLimit,
                brandLimit = brandLimit,
                originLimit = originLimit,
                creatorAccountLimit = creatorAccountLimit,
                creatorExperienceLimit = creatorExperienceLimit,
                memberAccountLimit = memberAccountLimit,
                advertiserId = advertiserId,
                publisherId = publisherId,
                commissionSchemeId = commissionSchemeId,
                currency = currency,
                )
            ) {
                updated = false
            }
        }

        ffGridX {

            ffGridXCell {
                ffInputText("Id", organization!!.id, true)
            }
            ffGridXCell {
                ffInputText("Owner", organization!!.ownerEmail, true)
            }
            ffGridXCell {
                ffTextArea("Name", name, { updated = true; name = it })
            }
            ffGridXCell {
                ffTextArea("Currency", currency, { updated = true; currency = it })
            }
            ffGridXCell {
                ffSelect("Type", type, listOf("IndividualRoot", "AffiliateAdvertiser", "AffiliatePublisher", "AffiliatePublisherMultiModel"), { updated = true; type = it })
            }
            ffGridXCell {
                ffSelect("Subscription status", subscription, listOf("Trial", "Active", "Suspended", "Cancelled", "PendingBusinessStandard", "PendingBusinessCreator", ), { updated = true; subscription = it })
            }
            ffGridXCell {
                ffSelect("Subscription tier", tier, listOf("PendingSelection", "BusinessStandard", "BusinessCreator", "BusinessEnterprise", "CreatorStandard", ), { updated = true; tier = it })
            }
            ffGridXCell {
                ffSelect("Subscription managed by", managedBy, listOf("Customer", "Platform" ), { updated = true; managedBy = it })
            }
            ffGridXCell {
                ffInputNumber("Experience limit", experienceLimit.toString(), false,
                    { updated = true; experienceLimit = it?.toInt() ?: experienceLimit },
                    "How many experiences can organization create")
            }
            ffGridXCell {
                ffTextArea("Awin advertiserId", advertiserId ?: "", { updated = true; advertiserId = it })
            }
            ffGridXCell {
                ffTextArea("Awin publisherId", publisherId ?: "", { updated = true; publisherId = it })
            }
            ffGridXCell {
                ffTextArea("Awin commissionSchemeId", commissionSchemeId ?: "", { updated = true; commissionSchemeId = it })
            }
            ffGridXCell {
                ffInputNumber("Brand limit", brandLimit.toString(), false,
                    { updated = true; brandLimit = it?.toInt() ?: brandLimit },
                    "How many brands can organization create")
            }
            ffGridXCell {
                ffInputNumber("Origin limit", originLimit.toString(), false,
                    { updated = true; originLimit = it?.toInt() ?: originLimit },
                    "How many origins can organization widgets be embedded on")
            }
            ffGridXCell {
                ffInputNumber("Creator account limit", creatorAccountLimit.toString(), false,
                    { updated = true; creatorAccountLimit = it?.toInt() ?: creatorAccountLimit },
                    "How many creators can organization invite and work with")
            }
            ffGridXCell {
                ffInputNumber("Creator experience limit", creatorExperienceLimit.toString(), false,
                    { updated = true; creatorExperienceLimit = it?.toInt() ?: creatorExperienceLimit },
                    "How many active experiences in total organization creators can have")
            }
            ffGridXCell {
                ffInputNumber("Member account limit", memberAccountLimit.toString(), false,
                    { updated = true; memberAccountLimit = it?.toInt() ?: memberAccountLimit },
                    "How many regular members can organization have")
            }
            ffGridXCell {
                ffButton({ if (!updated) attr("disabled", ""); onClick { if (updated) update() } }, color = FFColor.Primary) { Text("Save") }
            }

            ffGridXCell {
                Hr {  }
            }


            var memberInvitationEmail by remember { mutableStateOf("") }
            var memberInvitationUpdated by remember { mutableStateOf(false) }

            fun addAdmin() {
                api.organizationAddAdministrator(id, OrganizationInviteRequest(memberInvitationEmail)) {

                    memberInvitationEmail = ""
                    memberInvitationUpdated = false
                }
            }

            fun addMember() {
                api.organizationAddMember(id, OrganizationInviteRequest(memberInvitationEmail)) {

                    memberInvitationEmail = ""
                    memberInvitationUpdated = false
                }
            }
            fun addCreator() {
                api.organizationAddCreator(id, OrganizationInviteRequest(memberInvitationEmail)) {

                    memberInvitationEmail = ""
                    memberInvitationUpdated = false
                }
            }

            fun removeUser() {
                api.organizationRemoveUser(id, OrganizationInviteRequest(memberInvitationEmail)) {

                    memberInvitationEmail = ""
                    memberInvitationUpdated = false
                }
            }

            fun replaceOwner() {
                api.organizationReplaceOwner(id, OrganizationReplaceOwnerRequest(memberInvitationEmail)) {

                    memberInvitationEmail = ""
                    memberInvitationUpdated = false
                }
            }

            ffGridXCell {
                ffInputText("Add member", memberInvitationEmail, false, { memberInvitationUpdated = true; memberInvitationEmail = it }, "Adds existing system user as member in this organization without switching them over. No email invitations", "email")
            }

            ffGridXCell {
                ffButton({ if (!memberInvitationUpdated) attr("disabled", ""); onClick { if (memberInvitationUpdated) addMember() } }, color = FFColor.Primary) { Text("Add as member") }
            }

            ffGridXCell {
                ffButton({ if (!memberInvitationUpdated) attr("disabled", ""); onClick { if (memberInvitationUpdated) addCreator() } }, color = FFColor.Primary) { Text("Add as creator") }
            }

            ffGridXCell {
                ffButton({ if (!memberInvitationUpdated) attr("disabled", ""); onClick { if (memberInvitationUpdated) addAdmin() } }, color = FFColor.Primary) { Text("Add as admin") }
            }

            ffGridXCell {
                ffButton({ if (!memberInvitationUpdated) attr("disabled", ""); onClick { if (memberInvitationUpdated) removeUser() } }, color = FFColor.Primary) { Text("Remove user") }
            }

            ffGridXCell {
                ffButton({ if (!memberInvitationUpdated) attr("disabled", ""); onClick { if (memberInvitationUpdated) replaceOwner() } }, color = FFColor.Primary) { Text("Replace owner with this user") }
            }

            ffGridXCell {
                Hr {  }
            }

            var originLinkOrigin by remember { mutableStateOf("") }
            var originUpdated by remember { mutableStateOf(false) }

            fun linkOrigin() {
                api.organizationLinkOrigin(id, OrganizationOriginLinkRequest(id, "Normal", originLinkOrigin)) {

                    originLinkOrigin = ""
                    originUpdated = false
                }
            }
            fun unlinkOrigin() {
                api.organizationUnlinkOrigin(id, OrganizationOriginLinkRequest(id, "Normal", originLinkOrigin)) {

                    originLinkOrigin = ""
                    originUpdated = false
                }
            }

            ffGridXCell {
                ffInputText("Link origin", originLinkOrigin, false, { originUpdated = true; originLinkOrigin = it }, "Links specified origin to organization and invalidates related caches (enter without https://. For example contester.net)", "origin.com")
            }
            ffGridXCell {
                ffButton({ if (!originUpdated) attr("disabled", ""); onClick { if (originUpdated) linkOrigin() } }, color = FFColor.Primary) { Text("Link") }
            }
            ffGridXCell {
                ffButton({ if (!originUpdated) attr("disabled", ""); onClick { if (originUpdated) unlinkOrigin() } }, color = FFColor.Primary) { Text("Unlink") }
            }
        }
    }
}