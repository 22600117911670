package ff.components.complex

import androidx.compose.runtime.*
import com.soywiz.krypto.SecureRandom
import com.soywiz.krypto.encoding.base64
import ff.components.ffGridX
import ff.components.ffGridXCell
import ff.log.trace
import org.jetbrains.compose.web.attributes.Draggable
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.dom.I
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Style
import org.jetbrains.compose.web.dom.Text

data class FFTreeNode<T>(
    val current: T,
    var parent: FFTreeNode<T>?,
    val childrenInit: (FFTreeNode<T>) -> List<FFTreeNode<T>> = { listOf() }
) {

    private val identifier = SecureRandom.nextBytes(32).base64
//    private val identifier = current.toString()

    @Composable
    fun <K: T> render(context: TreeContext) {

        val self = this
        ffGridX(attrs = { classes("tree-level") }) {
            ffGridXCell(attrs = {
                classes("tree-item")
                onDragStart { context.onDragStart(self.identifier) }
                onDragEnd { context.onDragEnd(self.identifier) }
                onDragEnter {
                    if (context.dragged != self.identifier) {
                        context.onDragEnter(self.identifier)
                    }
                }
                if (context.dragged == identifier) {
                    classes("is-dragged")
                } else if (context.draggedOn == identifier) {
                    classes("is-dragged-on")
                }
            }) {
                ffGridX {
                    I(attrs = { classes("fi-list", "move-handle"); draggable(Draggable.True); }) { }
                    ffGridXCell(auto = true, attrs = { draggable(Draggable.False) }) {
                        I(attrs = { classes("fi-photo", "photo") }) { }
                        Text(current.toString())
                    }
                }
            }
            children.forEach {
                ffGridXCell {
                    it.render<K>(context)
                }
            }
        }
    }

    private val children: MutableList<FFTreeNode<T>> = mutableListOf()

    init {
        children.addAll(childrenInit(this))
    }

    fun replaceParent(childIdentifier: String, newParentIdentifier: String) {
        val newParent = findNode(newParentIdentifier)!!
        val child = findNode(childIdentifier)!!
        val oldParent = child.parent!!
        if (oldParent == newParent) {
            // do nothing
        } else {
            oldParent.children.remove(child)
            newParent.children.add(child)
            child.parent = newParent
        }
    }

    private fun findNode(searchIdentifier: String): FFTreeNode<T>? {
        trace("looking for '$searchIdentifier', considering '$identifier'")
        return if (this.identifier == searchIdentifier)
            this
        else children.firstNotNullOfOrNull { it.findNode(searchIdentifier) }
    }

    companion object {

        @Composable
        fun <T> renderTree(tree: FFTreeNode<T>) {

            Style {
                ".tree-level" {
                    property("padding-left", "1em")
                }

                ".tree-item" {
                    property("border", "1px solid #dadada")
                    property("width", "200px")
                    property("margin", "2px")
                    property("border-radius", "5px")
                }

                ".is-dragged" {
                    property("background-color", "#123")
                }

                ".is-dragged-on" {
                    property("background-color", "red")
                    property("margin-bottom", "2em")
                }

                ".tree-item .move-handle" {
                    property("cursor", "move")
                    property("background-color", "#dadada")
                    property("width", "30px")
                    property("padding", "0px 8px")
                    property("font-size", "24px")
                }
                ".tree-item .photo" {
                    property("font-size", "24px")
                    property("background-color", "#999")
                    property("width", "30px")
                    property("padding", "5px 8px")
                    property("cursor", "pointer")
                }
            }

            var dragged by remember { mutableStateOf<String?>(null) }
            var draggedOn by remember { mutableStateOf<String?>(null) }

            val context = TreeContext(
                dragged = dragged,
                draggedOn = draggedOn,
                onDragStart = {
                    trace("started dragging '$it'")
                    dragged = it
                },
                onDragEnd = {
                    trace("stopped dragging '$it', ended on '$draggedOn'")
                    tree.replaceParent(it, draggedOn!!)
                    dragged = null
                    draggedOn = null
                },
                onDragEnter = {
                    trace("dragged on '$it'")
                    draggedOn = it
                }
            )

            tree.render<T>(context)
        }
    }
}

data class TreeContext(
    val dragged: String?,
    val draggedOn: String?,
    val onDragStart: (String) -> Unit,
    val onDragEnd: (String) -> Unit,
    val onDragEnter: (String) -> Unit,
)