package ff.components.premade

import androidx.compose.runtime.*
import ff.auth.Auth0Client
import ff.auth.Auth0UserInfo
import ff.auth.ffAuthorized
import ff.components.*
import ff.log.trace
import ff.routing.Route
import ff.routing.Router
import ff.util.sleep
import org.jetbrains.compose.web.dom.*

data class PageContext<API>(
    val router: Router,
    val loadingStarted: () -> Unit,
    val loadingCompleted: () -> Unit,
    val api: API,
)

@Composable
fun <API> ffBasicAdmin(
    router: Router,
    menu: List<Route>,
    auth0Client: Auth0Client,
    api: API,
    isProduction: Boolean,
    content: @Composable (PageContext<API>) -> Unit,
) {

    ffAuthorized(auth0Client) {
        ffGridContainer(classes = listOf("basicAdminWrapper")) {
            ffGridX(marginX = true, marginY = true, paddingX = true, paddingY = true) {
                ffGridXCell(attrs = { classes("header") }) {

                    ffGridX(paddingX = true, paddingY = true) {
                        ffGridXCell(1, 1, 1) {
                            Img("/images/logo.png")
                        }

                        ffGridXCell(auto = true) {
                            var userInfo by remember { mutableStateOf<Auth0UserInfo?>(null) }
                            auth0Client.getUserInfo { userInfo = it }

                            if (userInfo != null) {
                                ffCallout(color = if (isProduction) FFColor.Alert else FFColor.Primary) {
                                    Text("Welcome back, ${userInfo!!.nickname} (${userInfo!!.email})")
                                    if (isProduction) {
                                        Text(" You're in production environment, be extra careful!")
                                    }
                                }
                            }
                        }
                    }
                }
                ffGridXCell(12, 4, 2, attrs = { classes("sidebar") }) {
                    ffGridX {
                        ffGridXCell {
                            H3 { Text("Sidebar") }
                            Hr {  }
                        }
                        ffGridXCell {
                            ffMenu(router, menu)
                        }
                    }
                }
                ffGridXCell(auto = true, attrs = { classes("content") }) {
                    ffGridX {
                        var loading by remember { mutableStateOf(true) }
                        ffGridXCell {
                            H3 {
                                Text(router.activeRoute.title)
                                if (loading) {
                                    Span({ classes("spinner-container") }) {
                                        Img("/images/spinner.svg")
                                    }
                                }
                            }
                            Hr {  }
                        }

                        ffGridXCell(attrs = { classes("content-container", "content-container-${if (loading) "loading" else "loaded"}") }) {
                            content(PageContext(router, { loading = true; trace("loading...") }, { sleep(1).then { loading = false; trace("loading completed") } }, api))
                        }
                    }
                }
                ffGridXCell(attrs = { classes("footer") }) {
                    Ul({ classes("menu") }) {
                        Li {
                            A(attrs = { onClick { auth0Client.logout() } }) {
                                Text("Logout")
                            }
                        }
                    }
                }
            }
        }
    }
}
